:root {
  --flash-color: #efeb9a;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  margin: 0;
  padding: 0;
  height: 100%;
  border: none;
}

#root {
  margin: 0;
  padding: 0;
  height: 100%;
  border: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

::-webkit-scrollbar {
  width: 0;
  visibility: hidden;
}

.flash {
  -webkit-animation: flash-anim 2s;
  -moz-animation: flash-anim 2s;
  -o-animation: flash-anim 2s;
  animation: flash-anim 2s;
  background-color: transparent;
}

@-webkit-keyframes flash-anim-anim {
  0% {
    background-color: transparent;
  }
  20% {
    background-color: var(--flash-color);
  }
  100% {
    background-color: transparent;
  }
}

@-moz-keyframes flash-anim {
  0% {
    background-color: transparent;
  }
  20% {
    background-color: var(--flash-color);
  }
  100% {
    background-color: transparent;
  }
}

@-o-keyframes flash-anim {
  0% {
    background-color: transparent;
  }
  20% {
    background-color: var(--flash-color);
  }
  100% {
    background-color: transparent;
  }
}

@keyframes flash-anim {
  0% {
    background-color: transparent;
  }
  20% {
    background-color: var(--flash-color);
  }
  100% {
    background-color: transparent;
  }
}
